<template>
  <v-form ref="form" lazy-validation :disabled="!!$auth.user()">
    <template v-if="!$auth.user()">
      <h3>{{ $t('onboarding.createAccount.onboardingAs') }}:</h3>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="user.type" row dense @change="changeType">
            <v-radio :label="$t('onboarding.createAccount.individual')" value="private"/>
            <v-radio :label="$t('onboarding.createAccount.company')" value="company"/>
          </v-radio-group>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12">
        <BaseMultiselect
          v-model="user.country"
          filled
          :items="registrationHints.country"
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.createAccount.countryOfResidence')"
          :loading="loadingRegistrationHints"
          :multiple="false"
          multi-select-data-type="country"
          required
          @input="changeType"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="user.first_name"
          filled
          required
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.createAccount.firstName')"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="user.last_name"
          filled
          required
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.createAccount.lastName')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.email"
          filled
          required
          :rules="emailRules"
          :label="$t('onboarding.createAccount.email')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.password"
          filled
          type="password"
          required
          :rules="passwordRules"
          :label="$t('onboarding.createAccount.password')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.repeatPassword"
          filled
          required
          type="password"
          :rules="repeatPasswordRules"
          :label="$t('onboarding.createAccount.repeatPassword')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template v-if="loadingAgreements">
          <v-skeleton-loader
            v-for="checkbox in checkboxes"
            :key="checkbox.key + '_skeleton'"
            type="list-item-avatar"
            class="mt-1 mb-6 ml-n6"
          />
        </template>
        <v-checkbox
          v-for="checkbox in checkboxes"
          v-else-if="!$auth.user()"
          :key="checkbox.key"
          v-model="user[checkbox.key]"
          false-value=""
          :true-value="agreements[checkbox.key].id"
          :required="checkbox.required"
          :rules="[(v) => !checkbox.required || !!v || $t('errors.agreeToContinue')]"
        >
          <template #label>
            <div v-if="checkbox.text">
              {{ checkbox.text }}
            </div>
            <div v-else>
              {{ $t('onboarding.createAccount.iAgreeTo') }}
              <a
                target="_blank"
                :href="`/agreements/${agreements[checkbox.key].id}`"
                @click.stop
              >
                <template v-if="agreements[checkbox.key]">
                  {{ agreements[checkbox.key].name }}
                </template>
                <template v-else>
                  {{ checkbox.key }}
                </template>
              </a>
            </div>
          </template>
        </v-checkbox>

        <v-checkbox v-model="user.subscribe_newsletters">
          <template #label>
            {{ $t('onboarding.createAccount.iAgreeThatMonestroMayProcessMyPersonalData') }}
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-btn
      tile
      large
      elevation="0"
      :loading="loading"
      dark
      class="mt-6"
      @click="submit"
    >
      {{ $t('onboarding.createAccount.next') }}
    </v-btn>
  </v-form>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "CreateAccount",
  emits: ["success", "change"],
  data() {
    return {
      agreements: {},
      loadingAgreements: true,
      loadingRegistrationHints: true,
      registrationHints: {},
      loading: false,
      emailRules: [
        (v) => !!v || this.$t('errors.emailIsRequired'),
        (v) =>
          new RegExp(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$", // `'
          ).test(v) || this.$t('errors.notCorrectEmail'),
      ],
      passwordRules: [
        (v) => !!v || this.$t('errors.passwordIsRequired'),
        (v) => (v && v.length >= 7) || this.$t('errors.passwordMustBeAtLeastSevenCharacters'),
      ],
      repeatPasswordRules: [
        (v) =>
          v == this.user.password ||
          this.$t('errors.passwordConfirmationDoesnotMatchPassword'),
      ],
      user: {
        type: "private",
        email: "",
        first_name: "",
        last_name: "",
        country: "",
        password: "",
        language: this.$i18n.locale,
        repeatPassword: "",
        general_terms: null,
        privacy_notice: null,
        subscribe_newsletters: false,
      },
    }
  },
  computed: {
    checkboxes() {
      return [
        { key: "general_terms",  required: true, text: "" },
        { key: "privacy_notice", required: true, text: "" },
      ]
    },
    authedUser() {
      return this.$auth.user()
    },
  },
  watch: {
    authedUser: {
      immediate: true,
      handler(user) {
        if (user) {
          this.user = user
          // TODO general_terms and privacy_notice are not part of the user model, so the checkboxes are not shown
        }
      },
    },
  },
  mounted() {
    this.fetchAgreements()
    this.fetchRegistrationHints()
    this.changeType()
    this.setReferralCodeCookie()
  },
  methods: {
    changeType() {
      this.$emit("change", this.user)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.user.language = this.$i18n.locale === 'bypass' ? 'en' : this.$i18n.locale
        this.$auth
          .register({
            data: { ...this.user, tmt_data: this.$cookies.get('tmt_data'), referral_code: this.$cookies.get('referralCode') },
          })
          .then(() => {
            this.$store.commit("pushMessage", {
              icon: "mdi-check",
              text: this.$t('infoMessages.confimationMessageHasBeenSent'),
              classHint: 'successfully-created-account',
            })
            this.$auth.login({
              url: "auth/jwt/create",
              data: {
                email: this.user.email,
                password: this.user.password,
              },
              redirect: false,
            }).then((user) => {
              this.loading = false
              user.data.type = this.user.type
              // this.$emit("next")  // XXX this is not needed… but why?
            }).catch(() => {
              this.loading = false
            })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    fetchAgreements() {
      this.loadingAgreements = true
      this.axios
        .get(`/agreements/`)
        .then((response) => {
          this.loadingAgreements = false
          for (const agreement of response.data.results) {
            this.agreements[agreement.category] = agreement
          }
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingAgreements = false
        })
    },
    fetchRegistrationHints() {
      this.loadingRegistrationHints = true
      this.axios
        .get(`/auth/registration_hints/`)
        .then((response) => {
          this.loadingRegistrationHints = false
          this.registrationHints = response.data
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingRegistrationHints = false
        })
    },
    setReferralCodeCookie() {
      if (this.$route.query.ref) {
        this.$cookies.set('referralCode', this.$route.query.ref)
      }
    },
  },
})
</script>
