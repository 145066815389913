<template>
  <v-container fluid>
    <div class="main-container pt-8">
      <v-row class="justify-center">
        <v-col cols="12" md="3" class="onboarding-steps">
          <v-list color="transparent">
            <v-subheader class="text-h6 text-uppercase mb-4" v-text="$t('onboarding.letsGetStarted')"/>
            <v-list-item-group v-model="currentStep" color="primary" mandatory>
              <v-fade-transition class="py-0" group leave-absolute tag="div">
                <div v-for="(step, index) in steps" :key="step.component + (step.key || '')">
                  <v-list-item :value="step">
                    <v-list-item-icon>
                      <v-progress-circular :rotate="90" color="black" :value="step.progress && step.progress()" :size="24" :class="step.progress && step.progress() >= 100 && 'done'">
                        <v-fade-transition>
                          <v-icon v-if="step.progress && step.progress() == 100" small dark :class="step.classHint && step.classHint()">mdi-check</v-icon>
                        </v-fade-transition>
                      </v-progress-circular>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-fade-transition>
                        <v-list-item-title class="text-uppercase" v-text="$t('onboarding.steps.' + step.component)"/>
                      </v-fade-transition>
                      <v-expand-transition v-if="step.subtitle">
                        <v-list-item-subtitle v-if="step.subtitle()" class="warning--text text--darken-2 mb-n2" v-text="step.subtitle()"/>
                      </v-expand-transition>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index != steps.length - 1" vertical :class="step.done && 'done'" class="ml-8 my-n4 foo"/>
                </div>
              </v-fade-transition>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="12" md="9">
          <v-card :disabled="currentStep.disabled && !!currentStep.disabled()" color="background">
            <v-card-text v-if="currentStep.component">
              <div class="mt-n2 mb-7 onboarding-title">
                <h2 key="currentStep.component" class="mb-0 d-flex align-center">
                  <span v-text="$t('onboarding.steps.' + currentStep.component)"/>
                </h2>
                <p v-if="currentStep.subTitleHint" key="currentStep.subTitle" class="mb-2 mt-1"
                   v-text="currentStep.subTitleHint"
                />
              </div>
              <div class="d-flex flex-column">
                <v-expand-transition v-for="step in steps" :key="step.component">
                  <div v-show="currentStep.component == step.component" :key="step.component" class="onboarding-content">
                    <component :is="step.component" onboarding @change="update" @next="goToNextStep" @pleaseUpdate="refetchResolver()"/>
                  </div>
                </v-expand-transition>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CreateAccount from "../components/CreateAccount"
import Veriff from "../components/Veriff"
import PersonalDataForm from "../../account/components/PersonalDataForm"
import CompanyForm from "../../companies/components/CompanyForm"
import ConfirmAssignment from "../components/ConfirmAssignment"
import TransferFunds from "../../transferfunds/components/TransferFunds"
import WellDone from "../components/WellDone"

import Vue from 'vue'
import goTo from 'vuetify/lib/services/goto'

import confetti from 'canvas-confetti'

export default Vue.extend({
  name: "OnboardingPage",
  components: {
    CreateAccount,
    Veriff,
    PersonalDataForm,
    CompanyForm,
    ConfirmAssignment,
    TransferFunds,
    WellDone,
  },
  data() {
    return {
      type: 'private',
      country: 'default',
      currentStepName: null,
      refetchResolver: () => { /* */ },
      transitionPromise: Promise.resolve(),
    }
  },
  computed: {
    currentStep: {
      get() {
        return this.steps.find(step => step.component == this.currentStepName)
      },
      set(value) {
        this.currentStepName = value.component
      },
    },
    user() {
      return this.$auth.user()
    },
    investment_account() {
      return this.$store.state.account
    },
    allSteps() {
      return [
        {
          component: 'CreateAccount',
          disabled: () => this.user,
          progress: () => this.user && 100,
        },
        {
          component: 'CompanyForm',
          condition: () => this.type == 'company' && this.country !== 'EE',
          disabled: () => !this.user || this.$store.state.account && this.$store.state.account.company,
          key: '-ee', // this is to make the animation slightly different (to make the element reappear instead of moving)
          progress: () => this.$store.state.account && this.$store.state.account.company && 100 || 0,
        },
        {
          component: 'Veriff',
          disabled: () => !this.user || this.user.verification === 'approved',
          condition: () => true,
          progress: () => this.user && { 'approved': 100, 'pending': 50 }[this.user.verification] || 0,
          subtitle: () => this.user && this.user.verification === 'pending' && this.$t('onboarding.verificationInProgress'),
          classHint: () => (this.user?.verification === 'approved' && 'successfully-verified'),
        },
        {
          component: 'PersonalDataForm',
          disabled: () => !this.user,
          condition: () => true,
          progress: () => this.user && ['phone', 'address', 'activity'].reduce((acc, value) => acc && this.user[value] !== null, true) && 100 || 0,
        },
        {
          component: 'CompanyForm',
          disabled: () => !this.user || this.$store.state.account && this.$store.state.account.company,
          condition: () => this.type == 'company' && this.country === 'EE',
          progress: () => this.$store.state.account && this.$store.state.account.company && 100 || 0,
        },
        {
          component: 'ConfirmAssignment',
          disabled: () => !this.user || !this.user.phone,
          condition: () => true,
          progress: () => this.$store.state.account && this.$store.state.account.is_confirmed_assignment && 100 || 0,
        },
        {
          component: 'TransferFunds',
          disabled: () => !this.user || this.user.birthdate && !this.user.is_18_years_old,
          condition: () => true,
          progress: () => this.user && this.$store.state.account && this.$store.state.account.is_funded && 100 ||
            this.user && this.$store.state.account && this.$store.state.account.payment_cards.length > 0 && 50 || 0,
          subtitle: () => this.user && this.$store.state.account && !this.$store.state.account.is_funded &&
            this.$store.state.account.payment_cards.length > 0 && this.$t('onboarding.transferFunds.verificationInProgress'),
          subTitleHint: this.$store.state.account ?
            this.$store.state.account.company ?
              this.$t('onboarding.transferFunds.hintForCompany') :
              this.$t('onboarding.transferFunds.hintForPrivateAccount') :
            '',
        },
        {
          component: 'WellDone',
          disabled: () => !this.user,
          condition: () => true,
          progress: () => this.$store.state.account && this.$store.state.account.is_approved && 100 || 0,
        },
      ]
    },
    steps() {
      let done = true
      return this.allSteps.filter(step => !step.condition || step.condition()).map((step) => {
        done = done && step.progress() >= 50  // Yes, this is using the value from the last iteration, this is intentional
        return { ...step, done }
      })
    },
    firstUndone() {
      return this.steps.slice(0, this.steps.length - 1).find(step => !step.done) || this.steps.find(step => step.progress() < 100)
    },
  },
  watch: {
    steps: {
      immediate: true,
      handler(value, oldValue) {
        this.transitionPromise = new Promise(resolve => setTimeout(resolve, 2_000))
        if (!oldValue || value.map(step => step.component).toString() !== oldValue.map(step => step.component).toString()) {
          this.goToNextStep(true)
        }
      },
    },
    firstUndone(value, oldValue) {
      if (!value && oldValue) {
        this.goToNextStep()
        this.confetti()
      }
    },
    investment_account: {
      immediate: true,
      handler(value, oldValue) {
        if (value.type) {
          this.type = value.type
        }
        if (value.id && !oldValue.id) {
          // We had no data about the investment account, now we have it, probably the page has
          // finished loading. Set the step!
          this.goToNextStep(true)
        }
      },
    },
  },
  mounted() {
    this.refetchData()
  },
  methods: {
    update(user) {
      if (!this.user || !this.investment_account) {
        this.type = user.type
        this.country = user.country
      }
    },
    refetchData(delay = 3000) {
      return new Promise(resolve => {
        // This promise will be resolved when:
        this.refetchResolver = resolve // something resolves it ahead of time
        setTimeout(resolve, delay) // or the timeout resolves it
      })
        // XXX OK, next we wait for transitions to settle… it's tricky, see another
        // comment in goToNextStep
        .then(async() => { for (let i = 0; i < 5; i++) { await this.transitionPromise } })
        .then(() => this.$auth.user() && this.$auth.fetch())
        .then(() => {
          const delay = this.$auth.user()?.verification === 'pending' ? 3_000 : 6_000
          this.refetchData(delay)
        })
        .catch(() => {
          // TODO ?
        })
    },
    goToNextStep(reset = false) {
      // Refetch everything. Because why not?
      // Actually, I think this is needed to make sure the previous state updates its data.
      // XXX However, we have to do it with some kind of a timeout because otherwise it might
      // visually break the transitions of steps (if they're different)… weird, yeah, maybe in Vue 3
      // it will be better if we can manage to avoid watcher triggers.
      this.refetchResolver()

      const firstUndone = this.firstUndone
      const nextUndone = reset || !this.currentStep
        ? firstUndone
        : this.steps.slice(this.steps.findIndex(step => step === this.currentStep) + 1).find(step => step.progress() < 50)

      const lastStep = this.steps.slice().reverse().find(step => step.progress() <= 50)
      this.currentStep = nextUndone || lastStep || firstUndone || this.steps[this.steps.length - 1]

      goTo(0)
    },
    confetti() {
      if (!this.$auth.user()?.is_beta_tester) {
        return
      }
      setTimeout(() => {
        confetti({
          angle: 55,
          spread: 50,
          particleCount: 80,
          startVelocity: 45 + 15,  // more than the other side to add assymetry and be more aligned with the “Well Done” center
          decay: 0.95,
          origin: { x: 0.0, y: 0.9 },
        })
        confetti({
          angle: 180 - 55,
          spread: 50,
          particleCount: 80,
          startVelocity: 45,
          decay: 0.95,
          origin: { x: 1.0, y: 0.9 },
        })
      }, 1000)
    },
  },
})
</script>

<style lang="scss">
.onboarding-steps {
  .fade-transition-enter-active {
    transition-delay: 0.22s !important;
    transition-duration: 0.8s !important;
  }

  .fade-transition-leave-active .v-divider {
    display: none !important; /* prevent a visual glitch */
  }

  .v-stepper__step {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

    font-size: 1.3rem;
  }

  .v-divider--vertical {
    height: 3rem;
    margin-left: 26px !important;
    border-width: 2px;
  }

  .v-divider--vertical.done {
    border-color: black; /* TODO right color */
  }

  .v-stepper__step--active .v-stepper__label {
    text-decoration: underline;
  }

  .v-progress-circular {
    background-color: white;
    border-radius: 100px;
    border-width: 5px;

    transition: background-color 0.5s ease-in-out;
  }

  .v-progress-circular.done {
    background-color: black;
  }

  .v-list-item--active .v-list-item__title {
    text-decoration: underline !important;
  }

  .v-list-item--active .v-icon {
    color: white !important;
  }

  .v-list .v-progress-circular.done {
    color: rgba(0, 0, 0, 1) !important;
  }
}

.onboarding-content.expand-transition-enter-active {
  transition-delay: 0.28s !important;
}

.onboarding-title h2 {
  min-height: 4rem;
}
</style>
