<template>
  <v-card :disabled="loadingFile" outlined color="transparent">
    <div class="sign-investor">
      <template v-if="file && !loadingFile">
        <div class="pdf-view">
          <iframe :src="file" width="100%" height="350px"/>
        </div>
        <p><v-btn text target="_blank" :href="file">{{ $t('onboarding.open') }}</v-btn></p>
      </template>
      <v-progress-circular v-else size="100" indeterminate class="d-block mb-6"/>


      <template v-if="!$store.state.account.is_confirmed_assignment">
        <template v-if="!smsCode.visible">
          <i18n path="onboarding.sms.smsWillBeSentTo">
            <template #phone>
              <v-chip small v-text="usersPhoneNumber"/>
            </template>
          </i18n>
          <div>
            <v-btn :loading="loadingResend" dark class="mt-6" @click="newSmsCode" v-text="$t('onboarding.sms.confirmWithSMS')"/>
          </div>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              <i18n path="onboarding.sms.smsSentTo">
                <template #phone>
                  <v-chip small>{{ usersPhoneNumber }}</v-chip>
                </template>
              </i18n>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label v-if="smsCode.error" class="red">{{ $t('onboarding.sms.codeIsNotCorrect') }}</label>
              <div class="sms-field">
                <v-text-field
                  v-model="smsCode.value"
                  outlined
                  dense
                  :label="$t('onboarding.sms.confirmationCode')"
                  autocomplete="one-time-code"
                  counter="4"
                  maxlength="4"
                />
              </div>
            </v-col>

            <v-col cols="12" md="9">
              <div class="multiple-buttons">
                <v-btn :loading="loading" dark @click="confirmSmsCode" v-text="$t('onboarding.sms.submitCode')"/>
                <v-btn :loading="loadingResend" text class="ml-2" @click="newSmsCode" v-text="$t('onboarding.sms.noSms')"/>
              </div>
            </v-col>
          </v-row>
        </template>
      </template>
      <template v-else>
        <div class="success-container">
          <div class="text-title text-uppercase text-dark">
            {{ $t('onboarding.signedSuccesfully') }}
          </div>
          <div class="success-container__text">
            {{ $t('onboarding.investorApplicationSignedSuccessfully') }}
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "ConfirmAssignment",
  emits: ["success"],
  data() {
    return {
      loading: false,
      loadingResend: false,
      loadingFile: false,
      session: '',
      smsCode: {
        visible: false,
        error: false,
        value: "",
        counter: 0,
      },
      file: null,
    }
  },
  computed: {
    investmentAccount() {
      return this.$store.state.account
    },
    usersPhoneNumber() {
      return this.$auth.user()?.phone
    },
    user() {
      return this.$auth.user()
    },
    language() {
      return this.$i18n.locale
    },
  },
  watch: {
    "investmentAccount.id": {
      immediate: false,
      handler(value) {
        if (value) {
          this.fetch()
        }
      },
    },
    "user.language": {
      immediate: true,
      handler(value) {
        if (value && !this.investmentAccount.is_confirmed_assignment) {
          this.fetch()
        }
      },
    },
    "language": {
      immediate: true,
      handler(value) {
        if (value && !this.investmentAccount.is_confirmed_assignment && this.$auth.user()) {
          this.loadingFile = true
        }
      },
    },
  },
  methods: {
    fetch() {
      this.file = null
      const docType = this.investmentAccount.type == 'private'
        ? "investor_agreement_private"
        : "investor_agreement_company"
      this.axios
        .get(`/documents/preview_document/?template__type=${docType}&investment_account=${this.investmentAccount.id}`)
        .then((response) => {
          this.loadingFile = false
          this.file = response.data.file
        })
    },
    newSmsCode() {
      this.loadingResend = true
      this.$http
        .post(`/investment_accounts_assignment/`, {
          investment_account: this.investmentAccount.id,
        })
        .then((response) => {
          this.loadingResend = false
          this.session = response.data.id
          this.$store.commit("pushMessage", {
            icon: "mdi-check",
            text: this.$t('onboarding.sms.verificationCodeHasBeenSent'),
          })
          this.smsCode = { visible: true, value: "" }
        })
        .catch(() => {
          this.loadingResend = false
        })
    },
    confirmSmsCode() {
      this.loading = true
      this.$http
        .patch(`/investment_accounts_assignment/${this.session}/`, {
          code: this.smsCode.value,
        })
        .then(() => {
          this.loading = false
          this.$emit("next")
        })
        .catch(() => {
          this.loading = false
          this.$store.commit("pushMessage", {
            icon: "mdi-check",
            text: this.$t('onboarding.sms.notCorrectVerificationCode'),
          })
        })
    },
  },
})
</script>
