<template>
  <div class="pb-6">
    <div v-if="status === 'pending'" class="pb-6 text-center">
      <h2 class="text-center">{{ $t("onboarding.verification.pleaseWaitWhileWeGetYouVerified") }}</h2>
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          {{ $t("onboarding.verification.verificationTimeMayDependsOnYourLocation") }}
        </v-col>
        <v-col cols="4">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="status === 'declined'" class="text-center">
      <h2 class="text-center">{{ $t("onboarding.verification.verificationRejected") }}</h2>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          {{ $t("onboarding.verification.verificationRejectedDescription") }}
        </v-col>
        <v-col cols="4">
          <div id="veriff-root" class="pt-6 pb-6">
            <v-btn dark class="mr-3 mb-3" block @click="initSession()">{{ $t('onboarding.verification.startVeriffBtn') }}</v-btn>
            <v-btn text class="mr-3 mb-3" @click="$emit('next')">{{ $t('onboarding.verification.skipVeriffBtn') }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p>
        {{ $t("onboarding.verification.prepareYourPassport") }}
      </p>

      <p>{{ $t("onboarding.verification.knowAboutVerifyingYourIdentity") }}</p>

      <ol>
        <li>{{ $t("onboarding.verification.haveYourPassportOrIdentityCardReady") }}</li>
        <li>{{ $t("onboarding.verification.identityVerificationTakeTenMinutes") }}</li>
        <li>{{ $t("onboarding.verification.makeSureYourFullNameOnTheImages") }}</li>
      </ol>
      <div id="veriff-root" class="pt-6 pb-6">
        <v-btn dark class="mr-3 mb-3" @click="initSession()">{{ $t('onboarding.verification.startVeriffBtn') }}</v-btn>
        <v-btn text class="mr-3 mb-3" @click="$emit('next')">{{ $t('onboarding.verification.skipVeriffBtn') }}</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { createVeriffFrame } from "@veriff/incontext-sdk"

import Vue from 'vue'

export default Vue.extend({
  name: "Veriff",
  emits: ["success", "canceled"],
  data() {
    return {
    }
  },
  computed: {
    status() {
      return this.$auth.user()?.verification
    },
  },
  methods: {
    initSession() {
      this.$http.get('/veriff/init/').then(response => {
        createVeriffFrame({
          lang: this.$auth.user().language,
          url: response.data.url,
          onEvent: () => {
            this.$emit("pleaseUpdate")
          },
        })
      })
    },
  },
})
</script>

<style lang="scss">
.veriff-description {
  display: none;
}

.veriff-submit {
  background-color: #1d1f24;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 8.5px 10px;
  display: inline-block;
  height: auto;
  padding: 0;
  cursor: pointer;
  min-width: 144px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
