<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="text-h4 text-uppercase my-2" v-text="$t('onboarding.wellDone.wellDone')"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card class="transparent text-h5 mt-7" max-width="40rem" flat tile v-text="$t('onboarding.wellDone.youCanStartInvesting')"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="py-9 mb-16">
          <v-btn to="/loans/primary" height="3rem" dark v-text="$t('onboarding.wellDone.loanMarket')"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "WellDone",
})
</script>
